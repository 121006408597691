import React from "react";
import CabinetLayout from "../components/cabinet/CabinetLayout";
import CabinetTopView from "../components/cabinet/CabinetTopView";
import EnsureLoginedView from "../components/cabinet/EnsureLoginedView";
import ExclusiveCollectionView from "../components/cabinet/ExclusiveCollectionView";
import SEO from "../components/seo";
import { L } from "../l10n/L10n";

const SpecialEditionPage = () => (
	<CabinetLayout>
		<SEO title={L.specialEditionPage.title}
		     description={L.specialEditionPage.desc}/>

		<EnsureLoginedView>
			<CabinetTopView title={L.specialEditionPage.title}
			                desc={L.specialEditionPage.desc}
			/>
			<ExclusiveCollectionView/>
		</EnsureLoginedView>
	</CabinetLayout>
);

export default SpecialEditionPage;