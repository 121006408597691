import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useApp } from "../../App";
import CabinetLayoutStyle from "./CabinetLayout.module.scss";
import CardView from "./CardView";
import SymbolCollectionStyles from "./SymbolCollection.module.scss";

const ExclusiveCollectionView = observer(() => {
	const app = useApp();
	const cardViews = app.assetConf.symbols.concat(app.assetConf.books)
		.filter(it => !it.unpack && !it.hidden)
		.map(it => {
			let symbolModels = app.store.symbols.queryByTemplateId(it.template_id);
			let booksModels = app.store.books.queryByTemplateId(it.template_id);
			return <CardView data={it}
			                 amount={symbolModels.length + booksModels.length}
			                 key={`symbol-view-${it.template_id}`}
			/>;
		});

	useEffect(() => {
		app.worldService.updateAssets().catch();
	}, []);

	return (
		<div className={classNames(CabinetLayoutStyle.main_container, SymbolCollectionStyles.container)}>
			{cardViews}
		</div>
	);
});

export default ExclusiveCollectionView;
